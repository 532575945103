import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import Axios from 'axios'
import { useForm } from 'react-hook-form';
import 
{ 
  spoilerOneTrue,
  spoilerTwoTrue,
  spoilerThreeTrue,
  spoilerFourTrue,
  spoilerFiveTrue,
  spoilersReset,
} from '../../../redux/reducers/mainPage/flagActions';
import { flagSelectors } from '../../../redux/reducers/mainPage/flagSelectors';
import { Calculate } from '../../../api/Calculate/Calculate';
import { MenuMobile } from './MenuMobile';
import { ServiceModal } from '../MainPage/ServiceModal';
import { ServiceCalckModal } from '../MainPage/ServiceCalckModal';
import { SpisokModal } from '../MainPage/SpisokModal';
import { PhoneModal } from '../MainPage/PhoneModal';
import { ReactComponent as Menu } from '../../../images/menu.svg';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import { ReactComponent as RadioFalse } from '../../../images/radioFalse.svg';
import { ReactComponent as RadioTrue } from '../../../images/radioTrue.svg';
import { ReactComponent as BtnBacks } from '../../../images/btnBacks.svg';
import { ReactComponent as BtnForward } from '../../../images/btnForward.svg';
import { ReactComponent as Wapp } from '../../../images/Wapp.svg';
import { ReactComponent as Tg } from '../../../images/Tgs.svg';
import { ReactComponent as Check } from '../../../images/checkmark.svg';
import { ReactComponent as ArrowDown } from '../../../images/select_arrow_down.svg';
import { ReactComponent as Frame } from '../../../images/frame.svg';
import SliderOne from '../../../images/slidermobile1.jpg';
import SliderTwo from '../../../images/slidermobile2.jpg';
import SliderThree from '../../../images/slidermobile3.jpg';
import SliderFour from '../../../images/slidermobile4.jpg';
import SlidePaysOne from '../../../images/paysmobile1.jpg';
import SlidePaysTwo from '../../../images/paysmobile2.jpg';
import SlidePaysThree from '../../../images/paysmobile3.jpg';
import Previews from '../../../images/previewsmobile.jpg';
import style from './MainPageMobile.module.scss';

// типизация данных для отправки с формы
type TFormValues = {
  phone: string;
  name: string;
  type: string;
};

/**
 * @function {MainPageMobile} - Компонент заглавной страницы для приложения "Клининг", мобильная версия.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий заглавную страницу.
 */

function MainPageMobile(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });

  const { spoilerOne, spoilerTwo, spoilerThree, spoilerFour, spoilerFive } = useAppSelector(flagSelectors);

  const [check, setCheck] = useState(false);
  const [openSpoiler, setOpenSpoiler] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrolls, setScrolls] = useState(0);
  const [imageSlide, setImageSlide] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [isAnimating, setIsAnimating] = useState(false); // Флаг анимации
  const [currentPaysIndex, setCurrentPaysIndex] = useState(0);
  const [house, setHouse] = useState(false);
  const [houseValue, setHouseValue] = useState<string | null>(null);
  const [square, setSquare] = useState(false);
  const [squareValue, setSquareValue] = useState<string | null>(null);
  const [service, setService] = useState(false);
  const [serviceValue, setServiceValue] = useState<string | null>(null);
  const [serviceDescript, setServiceDescript] = useState(false);
  const [serviceDescriptValue, setServiceDescriptValue] = useState<string | null>(null);
  const [calculate, setCalculate] = useState<number | null>(null);
  const [mask, setMask] = useState("+7(9__)___-__-__");
  const [cursorPosition, setCursorPosition] = useState(5.2);
  const [label, setLabel] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [openCalculate, setOpenCalculate] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [cleaner, setCleaner] = useState(false);
  const [openSpisok, setOpenSpisok] = useState(false);
  const [openSpisokOne, setOpenSpisokOne] = useState(false);
  const [openSpisokTwo, setOpenSpisokTwo] = useState(false);
  const [openSpisokThree, setOpenSpisokThree] = useState(false);
  const [openSpisokFour, setOpenSpisokFour] = useState(false);
  const [openSpisokFive, setOpenSpisokFive] = useState(false);
  const [openSpisokSix, setOpenSpisokSix] = useState(false);
  const [openSpisokOfer, setOpenSpisokOfer] = useState(false);
  const [openSpisokPolitic, setOpenSpisokPolitic] = useState(false);

  const api = `https://402.m-dels.ru/order`;

  // загружаем для слайдера блока слайдер все изображения
  const getImageSlide = (): string[] => {
    return [SliderOne, SliderTwo, SliderThree, SliderFour];
  };

  // загружаем для слайдера блока 2 все изображения
  const getImage = (): string[] => {
    return [SlidePaysOne, SlidePaysTwo, SlidePaysThree];
  };
  
  // определяем стиль для индекса изображения блока слайдера (активный, следующий и предыдущий, остальные скрываем)
  const getPositionClassSlider = (index: number): string => {
    if (index === currentIndex) return style.active;
    if (index === (currentIndex + 1) % images.length) return style.next;
    if (index === (currentIndex - 1 + images.length) % images.length) return style.prev;
    return style.hidden;
  };

  // определяем стиль для индекса изображения блока 2 (активный, следующий и предыдущий, остальные скрываем)
  const getPositionClass = (index: number): string => {
    if (index === currentPaysIndex) return style.active;
    if (index === (currentPaysIndex + 1) % images.length) return style.next;
    if (index === (currentPaysIndex - 1 + images.length) % images.length) return style.prev;
  };

  // переключение слайдера вперед
  const handleClickForward = () => {
    if (currentIndex === 3) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(prevState => prevState + 1);
    }
  };

  // переключение слайдера назад
  const handleClickBack = () => {
    if (currentIndex === 0) {
      setCurrentIndex(3);
    } else {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  // переключаме слайдера второго блока вперед
  const handleClickForwardPays = () => {
    if (isAnimating) return; // Если анимация идет, ничего не делаем
    setIsAnimating(true);

    // Сбрасываем флаг анимации после завершения
    setTimeout(() => {
      setIsAnimating(false);
      setCurrentPaysIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500);
  };

  // переключение слайдера второго блока назад
  const handleClickBackPays = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      setCurrentPaysIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }, 500);
  };

  // функция для перехода в Whats App
  const handleWa = () => {
    const url = "https://api.whatsapp.com/message/232ZXS3OASO5P1?autoload=1&app_absent=0";
    window.open(url, '_blank'); 
  };

  // функция для перехода в Telegram
  const handleTg = () => {
    const url = "https://t.me/LaStik444";
    window.open(url, '_blank'); 
  };

  const handleClose = () => {
    setHouse(false);
    setSquare(false);
    setService(false);
    setServiceDescript(false);
    setLabel(false);
  }

  // функция для установки типа помещения
  const handleHouse = (name: string) => {
    setHouseValue(name);
  };

  // функция для установки площади
  const handleSquare = (value: number) => {
    setSquareValue(value.toString());
  };

  // функция для установки услуги
  const handleService = (name: string) => {
    setServiceValue(name);
  };

  // функция для установки состава услуги
  const handleServiceDescript = (name: string) => {
    setServiceDescriptValue(name);
  };

  // функция для вкл/выкл принятия пользовательского соглашения
  const handleCheck = () => {
    setCheck(!check);
  };

  // функция для переключения спойлеров
  const handleSpoiler = (name: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (openSpoiler === name) {
      setOpenSpoiler(null);
      dispatch(spoilersReset());
    } else {
      setOpenSpoiler(name);
      switch(name) {
        case "spoilerOne": dispatch(spoilerOneTrue()); break;
        case "spoilerTwo": dispatch(spoilerTwoTrue()); break;
        case "spoilerThree": dispatch(spoilerThreeTrue()); break;
        case "spoilerFour": dispatch(spoilerFourTrue()); break;
        case "spoilerFive": dispatch(spoilerFiveTrue()); break;
        default: break;
      }
    }
  };

  // функция для ввода телефона
  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result = event.target.value.replace(/\D/g, '');
    if (result.length > 0) {
      setMask(`+7(9${result[0]}_)___-__-__`);
      setCursorPosition(6);
    }
    if (result.length > 1) {
      setMask(`+7(9${result.slice(0, 2)})___-__-__`);
      setCursorPosition(7);
    }
    if (result.length > 2) {
      setMask(`+7(9${result.slice(0, 2)})${result[2]}__-__-__`);
      setCursorPosition(8.5);
    }
    if (result.length > 3) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 4)}_-__-__`);
      setCursorPosition(9.5);
    }
    if (result.length > 4) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-__-__`);
      setCursorPosition(10.5);
    }
    if (result.length > 5) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result[5]}_-__`);
      setCursorPosition(11.5);
    }
    if (result.length > 6) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-__`);
      setCursorPosition(12.5);
    }
    if (result.length > 7) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result[6]}_`);
      setCursorPosition(13.7);
    }
    if (result.length > 8) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result.slice(7, 9)}`);
      setCursorPosition(14.7);
    }
    if (result.length === 0) {
      setMask(`+7(9__)___-__-__`);
      setCursorPosition(5.2);
    }

    event.target.value = result.slice(0, 9);
    
    setValue('phone', result.slice(0, 9));
  };

  // открываем модалку для клинера
  const handleCleaner = () => {
    setOpenPhone(true);
    setCleaner(true);
  };

  // функция для закрытия модального окна отображающего услуги, оферту и политику соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokOne(false);
    setOpenSpisokTwo(false);
    setOpenSpisokThree(false);
    setOpenSpisokFour(false);
    setOpenSpisokFive(false);
    setOpenSpisokSix(false);
    setOpenSpisokOfer(false);
    setOpenSpisokPolitic(false);
  }

  // Отправка формы
  const onSubmit = async (formData: TFormValues) => {
    if (!watch('name') || !watch('phone') || watch('phone').length !== 9) {
      return null;
    } else if(!check) {
      return null;
    } else {
      try {
        const dataToSend = {
          'order-info': {
            phone: formData.phone = mask,
            name: formData.name,
            type: formData.type || '',
          }
        };
  
        await Axios.post(api, dataToSend)
          .then((response) => {
            console.log(response);
          });
      } catch (error) {
        console.log('Error:', error);
      }
    }
  };


   // считываем скролл по оси Y
   useEffect(() => {
    const handleScroll = () => {
      setScrolls(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, []);


  // Устанавливаем пути к изображениям в стейт для доступа к ним в JSX
  useEffect(() => {
    const imageSourcesSlider = getImageSlide();
    const imageSources = getImage();
    
    setImageSlide(imageSourcesSlider);
    setImages(imageSources);
  }, []);

  // отображения данных работы калькулятора
  useEffect(() => {
    const calculateResult = Calculate({
      house: houseValue, 
      serviceValue, 
      serviceValueType: serviceDescriptValue, 
      square: squareValue,
    });
    setCalculate(calculateResult);
  }, [houseValue, serviceValue, serviceDescriptValue, squareValue]);

  // Меняем каждые 5 секунд слайд блока слайдер
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === 3) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => (prevIndex + 1));
      }
      
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  // Меняем каждые 5 секунд слайд блока 2
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentPaysIndex === 2) {
        setCurrentPaysIndex(0);
      } else {
        setCurrentPaysIndex((prevIndex) => (prevIndex + 1));
      }
      
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentPaysIndex]);

  return (
    <div className={style.main} onClick={handleClose}>
      <div className={style.sliders}>
        <div className={scrolls > 0 ? style.tops : style.top}>
          <button className={style.btn} onClick={() => setOpenMenu(true)}>
            <Menu className={style.menu} />
          </button>
          <Logo className={style.logo} />
        </div>
        {imageSlide.map((item, index) => {
          const position = getPositionClassSlider(index);
          return (
            <img
              key={index}
              className={`${style.slideImg} ${position}`}
              src={item}
              alt={`Slide number ${index + 1}`}
              />
          );
        })}
        {currentIndex === 0 ? (
          <>
            <div className={style.slideOneTitle}>Все меньше шансов что-нибудь сделать сегодня</div>
            <div className={style.slideOneCaption}>и все обиднее за то, чего не<br /> успел вчера</div>
          </>
        ) : currentIndex === 1 ? (
          <>
            <div className={style.slideTwoCaption}>
              <div>
                <span>Человек на уборку тратит<br /><span className={style.upper}>300 ЧАСОВ</span> в год</span>
              </div>
              <div>
                <span>За жизнь примерно<br /><span className={style.upper}>18000 часов</span></span>
              </div>
              <div>
                <span>Это соответствуето<br /><span className={style.upper}>2 годам</span> счастливой жизни</span>
              </div>
            </div>
          </>
        ) : currentIndex === 2 ? (
          <>
            <div className={style.slideThreeTitle}>Мы знаем, что потраченного времени не вернуть</div>
            <div className={style.slideThreeCaption}>Не тратьте его на ненужные вещи</div>
          </>
        ) : currentIndex === 3 ? (
          <div className={style.slideFourTitle}>Доверьте уборку нам!</div>
        ) : null}
        <div className={style.slider}>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(0)}>
            {currentIndex === 0 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(1)}>
            {currentIndex === 1 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(2)}>
            {currentIndex === 2 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(3)}>
            {currentIndex === 3 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button> 
        </div>
        <button className={style.btnBack} type="button" onClick={handleClickBack}>
          <BtnBacks />
        </button>
        <button className={style.btnForward} type="button" onClick={handleClickForward}>
          <BtnForward />
        </button>
      </div>
      <div className={style.pays} id="pays">
        {images.map((item, index) => {
            const position = getPositionClass(index);
            return (
              <img
                key={index}
                className={`${style.slidePaysImg} ${position}`}
                src={item}
                alt={`Slide number ${index + 1}`}
                />
            );
          })}
          {currentPaysIndex === 0 ? (
            <>
              <div className={style.titleOne}>Уборка<br /> офиса</div>
              <div className={style.textOne}>от 1500 ₽</div>
            </>
          ) : currentPaysIndex === 1 ? (
            <>
              <div className={style.titleTwo}>Уборка квартиры</div>
              <div className={style.textTwo}>от 2000 ₽</div>
            </>
          ) : currentPaysIndex === 2 ? (
            <>
              <div className={style.titleThree}>Уборка торговой точки</div>
              <div className={style.textThree}>от 1800 ₽</div>
            </>
          ) : null}
          <button className={style.btnBack} type="button" onClick={handleClickBackPays}>
            <BtnBacks />
          </button>
          <button className={style.btnForward} type="button" onClick={handleClickForwardPays}>
            <BtnForward />
          </button>
          <button className={style.btn} type="button" onClick={() => setOpenService(true)}>Заказать услугу</button>
          <div className={style.sliderPays}>
            <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(0)}>
              {currentPaysIndex === 0 ? <RadioTrue /> : <RadioFalse className={style.false} />}
            </button>
            <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(1)}>
              {currentPaysIndex === 1 ? <RadioTrue /> : <RadioFalse className={style.false} />}
            </button>
            <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(2)}>
              {currentPaysIndex === 2 ? <RadioTrue /> : <RadioFalse className={style.false} />}
            </button>
          </div>
      </div>
      <div className={style.calculates} id="calculates">
        <div className={style.title}>Подбери уборку для себя</div>
        <div className={style.caption}>Не удалось подобрать, мы обязательно поможем</div>
        <button type="button" className={style.btnWa} onClick={handleWa}>
          <Wapp className={style.wapp} />
        </button>
        <button type="button" className={style.btnTg} onClick={handleTg}>
          <Tg className={style.tg} />
        </button>
        <button
          type="button" 
          className={style.house} 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setHouse(prevState => !prevState);
            e.stopPropagation();
          }}
        >
          <span>
            {houseValue === "office" ? "Офис" : houseValue === "flat" ? "Квартира" :  houseValue === "point" ? "Торговая точка"  : "Выберите тип помещения"}
          </span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={house ? '' : style.houseHidden}>
          <button type="button" className={style.houseOptionOne} onClick={() => handleHouse("office")}>
            Офис
          </button>
          <button type="button" className={style.houseOptionTwo} onClick={() => handleHouse("flat")}>
            Квартира
          </button>
          <button type="button" className={style.houseOptionThree} onClick={() => handleHouse("point")}>
            Торговая точка
          </button>
        </div>
        <button
          type="button" 
          className={style.square} 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setSquare(prevState => !prevState);
            e.stopPropagation();
          }}
        >
          <span>{squareValue ? squareValue : "Выберите площадь"}</span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={square ? '' : style.squareHidden}>
          <button type="button" className={style.squareOptionOne} onClick={() => handleSquare(40)}>40</button>
          <button type="button" className={style.squareOptionTwo} onClick={() => handleSquare(50)}>50</button>
          <button type="button" className={style.squareOptionTwo} onClick={() => handleSquare(60)}>60</button>
          <button type="button" className={style.squareOptionTwo} onClick={() => handleSquare(70)}>70</button>
          <button type="button" className={style.squareOptionTwo} onClick={() => handleSquare(80)}>80</button>
          <button type="button" className={style.squareOptionThree} onClick={() => handleSquare(90)}>90</button>
        </div>
        <button
          type="button" 
          className={style.service} 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setService(prevState => !prevState);
            e.stopPropagation();
          }}
        >
          <span>
            {serviceValue ? serviceValue : "Выберите услугу"}
          </span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={service && houseValue ? '' : style.serviceHidden}>
          <button type="button" className={style.serviceOptionOne} onClick={() => handleService("Поддерживающая уборка")}>
            Поддерживающая уборка
          </button>
          <button type="button" className={style.serviceOptionTwo} onClick={() => handleService("Генеральная")}>
            Генеральная
          </button>
          <button type="button" className={style.serviceOptionThree} onClick={() => handleService("Послестроительная")}>
            Послестроительная
          </button>
        </div>
        <button
          type="button" 
          className={style.serviceDescript} 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setServiceDescript(prevState => !prevState);
            e.stopPropagation();
          }}
        >
          <span>
            {serviceDescriptValue ? serviceDescriptValue : "Выберите состав услуги"}
          </span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={serviceDescript ? '' : style.serviceDescriptHidden}>
          {houseValue === "office" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "office" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() =>handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "office" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "flat" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "flat" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.serviceDescriptOptionOne} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
              <button type="button" className={style.serviceDescriptOptionTwo} onClick={() => handleServiceDescript("Ассоциальная")}>
                Ассоциальная
              </button>
              <button type="button" className={style.serviceDescriptOptionTwo} onClick={() => handleServiceDescript("Последствия вечеринки")}>
                Последствия вечеринки
              </button>
              <button type="button" className={style.serviceDescriptOptionThree} onClick={() => handleServiceDescript("Предпродажная")}>
                Предпродажная
              </button>
            </>
          ) : houseValue === "flat" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "point" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "point" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : houseValue === "point" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.serviceDescriptOption} onClick={() => handleServiceDescript("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : null}
        </div>
        <div className={style.pay}>Цена</div>
        <div className={style.rouble}>от {calculate ? calculate.toLocaleString('ru-RU') : '0 000'} ₽</div>
        <button className={style.order} type="button" onClick={() => setOpenCalculate(true)}>Заказать</button>
      </div>
      <div className={style.previews}>
        <img className={style.previewsImg} src={Previews} alt="Previews" />
        <div className={style.title}>LaStik</div>
        <div className={style.caption}>Обслуживание, которого вы заслуживаете!</div>
        <Frame style={{ position: 'absolute', top: '324px', left: '38px' }} />
        <div className={style.textOne}>Обученные клинеры</div>
        <Frame style={{ position: 'absolute', top: '401px', left: '38px' }} />
        <div className={style.textTwo}>Профессиональное оборудование</div>
        <Frame style={{ position: 'absolute', top: '501px', left: '38px' }} />
        <div className={style.textThree}>Безопасные моющие средства</div>
        <Frame style={{ position: 'absolute', top: '601px', left: '38px' }} />
        <div className={style.textFour}>Высокие стандарты обслуживания</div>
      </div>
      <div className={style.marketings}>
        <div className={style.top}>
          <div className={style.number}>7</div>
          <div className={style.text}>лет на рынке</div>
        </div>
        <div className={style.center}>
          <div className={style.number}>1731</div>
          <div className={style.text}>довольных клиента</div>
        </div>
        <div className={style.bottom}>
          <div className={style.number}>1,7</div>
          <div className={style.text}>млн. чистых квадратных метров</div>
        </div>
      </div>
      <form className={style.orders} onSubmit={handleSubmit(onSubmit)} id="orders">
        <div className={style.title}>Заказать уборку</div>
        <input className={style.name} type="text" placeholder="Имя" {...register('name')} />
        <label 
          className={style.phone} 
          htmlFor="phone" 
          onClick={(e: React.MouseEvent<HTMLLabelElement>) =>{
            e.stopPropagation();
            setLabel(true);
          }}
        >
          {mask}
          <input
            id="phones"
            className={style.phones}
            type="text"
            {...register('phone', {onChange: handlePhone})}
          />
          <span className={label ? style.cursor : style.cursorHidden} style={{ left: `${cursorPosition * 10}px` }} />
        </label>
        <button className={style.btns} type="submit">Заказать услугу</button>
        <div className={style.text}>
          <span>Принимаю <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOfer(true)}}>пользовательское соглашение</span>. Соглашаюсь на <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokPolitic(true)}}>обработку персональных данных</span>.</span>
        </div>
        <button type="button" className={style.check} onClick={handleCheck}>
          {check? <Check className={style.checks} /> : null}
        </button>
      </form>
      <div className={style.faqs} id="faqs">
        <div className={style.title}>Частые вопросы</div>
        <div className={style.spoilers}>
          <details className={style.spoilerOne} open={openSpoiler === 'spoilerOne'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerOne', event)}>Как сделать заказ на уборку?</summary>
            {spoilerOne ?
              <div className={style.spoilerText}>Нажмите значок WA или Telegram, напишите тип объекта, вид уборки (генеральная, поддерживающая или послестроительная) напишите желаемую дату и время. При необходимости ответьте на дополнительные вопросы менеджера. Также вы можете оставить запрос на обратный звонок.</div>
            : null}
          </details>
          <details className={style.spoilerTwo} open={openSpoiler === 'spoilerTwo'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerTwo', event)}>Я могу оставить клинера одного в квартире??</summary>
            {spoilerTwo ?
              <div className={style.spoilerText}>Да, можете оставить клинера в квартире одного, при этом за полчаса до окончания, он с Вами свяжется.</div>
            : null}
          </details>
          <details className={style.spoilerThree} open={openSpoiler === 'spoilerThree'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerThree', event)}>Есть ли скидки на уборки?</summary>
            {spoilerThree ?
              <div className={style.spoilerText}>Да, при первом заказе действует скидка по кодовому слову #ЧИСТОЧИСТО</div>
            : null}
          </details>
          <details className={style.spoilerFour} open={openSpoiler === 'spoilerFour'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFour', event)}>Нужно ли что-то приготовить для<br /> клинера?</summary>
            {spoilerFour ?
              <div className={style.spoilerText}>Все моющие средства и инвентарь клинер привозит с собой, но с вашего позволения и при необходимости может воспользоваться Вашим пылесосом (в случае поддерживающей уборки).</div>
            : null}
          </details>
          <details className={style.spoilerFive} open={openSpoiler === 'spoilerFive'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFive', event)}>Что входит в уборку?</summary>
            {spoilerFive ?
              <div className={style.spoilerText}>В нижней части сайта расположена информация о составе услуг, при клике на соответствующую услугу, вы сможете ознакомится с информацией о составе услуг.</div>
            : null}
          </details>
        </div>
      </div>
      <div className={style.footers} id="footers">
        <div className={style.logo}>
          <Logo />
        </div>
        <div className={style.phone}>Тел: 8 (8182) 43-32-10</div>
        <button type="button" className={style.btn} onClick={() => setOpenPhone(true)}>Заказать звонок</button>
        <div className={style.lastik}>Lastik</div>
        <div className={style.textL}>
          <span><span className={style.link}>Помощь</span><br/><span className={style.link} onClick={handleCleaner}>Стать клинером</span></span>
        </div>
        <div className={style.clean}>Уборка</div>
        <div className={style.textC}>
          <span>
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOne(true)}}>Поддерживающая</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokTwo(true)}}>Генеральная-стандарт</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokThree(true)}}>Генеральная-ассоциальная</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokFour(true)}}>Генеральная-после вечеринки</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokFive(true)}}>Генеральная-предпродажная</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokSix(true)}}>Послестроительная</span>
          </span>
        </div>
        <div className={style.contract}>
          <span>Используя информационный ресурс https://lastik.vip Вы соглашаетесь с условиями <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOfer(true)}}>Оферты</span> и соглашаетесь с <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokPolitic(true)}}>Политикой конфиденциальности</span></span>
        </div>
        <div className={style.lastiks}>© 2024, ООО «КАКТУС»</div>
      </div>
      <MenuMobile open={openMenu} onClose={() => setOpenMenu(false)} />
      <ServiceModal open={openService} onClose={() => setOpenService(false)} />
      <ServiceCalckModal
        open={openCalculate}
        onClose={() => setOpenCalculate(false)}
        house={houseValue}
        square={squareValue}
        serviceType={serviceValue}
      />
      <PhoneModal open={openPhone} onClose={() => setOpenPhone(false)} cleaner={cleaner} />
      <SpisokModal 
        open={openSpisok}
        one={openSpisokOne}
        two={openSpisokTwo}
        three={openSpisokThree}
        four={openSpisokFour}
        five={openSpisokFive}
        six={openSpisokSix}
        ofer={openSpisokOfer}
        politic={openSpisokPolitic}
        onClose={handleSpisokClose} 
      />
    </div>
  );
};

export default MainPageMobile;
