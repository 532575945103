export class BaseUserCredentialsModel {
    public login: string = '';
    public password: string = '';

    constructor(data: Partial<BaseUserCredentialsModel> = {}) {
        if (!data.login) {
            data.login = data["email"]
        }
        Object.assign(this, data);
    }
}

abstract class BaseUser {
    public email: string = '';
    public password: string = '';
    public surname: string = '';
    public name: string = '';
    public patronymic: string = '';

    protected constructor(data: Partial<BaseUser> = {}) {
        Object.assign(this, data);
    }
}

export class IndividualUserModel extends BaseUser {
    constructor(data: Partial<IndividualUserModel> = {}) {
        super();
        Object.assign(this, data);
    }
}

export class CorporateUserModel extends BaseUser {
    public company_name: string = '';
    public company_tid: string = '';
    public rank: string = '';

    constructor(data: Partial<CorporateUserModel> = {}) {
        super();
        Object.assign(this, data);
    }
}

export class CleanerUserModel extends BaseUser {
    constructor(data: Partial<CleanerUserModel> = {}) {
        super();
        Object.assign(this, data);
    }
}