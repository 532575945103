import { NavLink } from 'react-router-dom';
import { ReactComponent as Close } from '../../../images/close.svg';
import style from './MenuMobile.module.scss';

type TModalProps = {
  open: boolean;
  onClose: () => void;
};

/**
 * @function {MenuMobile} - Компонент модального окна меню, мобильная версия.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий модальное окно меню.
 */

export const MenuMobile = ({ open, onClose }: TModalProps): React.JSX.Element => {

  if (!open) return null;
  return (
    <div className={style.main}>
      <button className={style.btn} onClick={onClose}>
        <Close />
      </button>
      <div className={style.text}>
        <a className={style.link} href="#orders">Заказать уборку</a>
      </div>
      <div className={style.text}>
        <a className={style.link} href="#pays">Уборки</a>
      </div>
      <div className={style.text}>
        <a className={style.link} href="#calculates">Калькулятор</a>
      </div>
      <div className={style.text}>
        <a className={style.link} href="#faqs">Вопросы</a>
      </div>
      <div className={style.text}>
        <a className={style.link} href="#footers">Контакты</a>
      </div>
      <div className={style.text}>
        <NavLink className={style.link} to="/cabinet">Личный кабинет</NavLink>
      </div>
    </div>
  );
};
