import { configureStore, combineReducers } from '@reduxjs/toolkit'
import loadingReducer from './reducers/loading'
import flagReducer from "./reducers/mainPage/flagSlice";
import calculatorReducer from "./reducers/calculator/calculatorSlice";

const multipleReducer = combineReducers({
  loadingReducer,
  flagReducer,
  calculatorReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: multipleReducer,
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
const store = setupStore();

export default store;
