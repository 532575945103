import { useEffect, useState } from 'react';
import Axios from 'axios'
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import 
{ 
  spoilerOneTrue,
  spoilerTwoTrue,
  spoilerThreeTrue,
  spoilerFourTrue,
  spoilerFiveTrue,
  spoilersReset,
} from '../../../redux/reducers/mainPage/flagActions';
import { flagSelectors } from '../../../redux/reducers/mainPage/flagSelectors';
import { Calculate } from '../../../api/Calculate/Calculate';
import { PhoneModal } from './PhoneModal';
import { ServiceModal } from './ServiceModal';
import { ServiceCalckModal } from './ServiceCalckModal';
import { SpisokModal } from './SpisokModal';
import SliderOne from '../../../images/slider1.jpg';
import SliderTwo from '../../../images/slider2.jpg';
import SliderThree from '../../../images/slider3.jpg';
import SliderFour from '../../../images/slider4.jpg';
import { ReactComponent as Logo } from '../../../images/logoVersionTwo.svg';
import { ReactComponent as Phone } from '../../../images/phone.svg';
import { ReactComponent as LogIn } from '../../../images/logIn.svg';
import { ReactComponent as Wapp } from '../../../images/Wapp.svg';
import { ReactComponent as Tg } from '../../../images/Tgs.svg';
import { ReactComponent as RadioFalse } from '../../../images/radioFalse.svg';
import { ReactComponent as RadioTrue } from '../../../images/radioTrue.svg';
import { ReactComponent as BtnBack } from '../../../images/btnBacks.svg';
import { ReactComponent as BtnForward } from '../../../images/btnForward.svg';
import { ReactComponent as ArrowDown } from '../../../images/select_arrow_down.svg';
import { ReactComponent as Frame } from '../../../images/frame.svg';
import { ReactComponent as Check } from '../../../images/checkmark.svg';
import style from './MainPage.module.scss';

// типизация данных для отправки с формы
type TFormValues = {
  phone: string;
  name: string;
  type: string;
};

/**
 * @function {MainPage} - Компонент заглавной страницы для приложения "Клининг", десктопная версия.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий заглавную страницу.
 */

function MainPage(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });
  const { spoilerOne, spoilerTwo, spoilerThree, spoilerFour, spoilerFive } = useAppSelector(flagSelectors);
  const [house, setHouse] = useState('');
  const [square, setSquare] = useState<string | null>(null);
  const [service, setService] = useState(false);
  const [serviceValue, setServiceValue] = useState<string | null>(null);
  const [serviceDescript, setServiceDescript] = useState(false);
  const [serviceValueType, setServiceValueType] = useState<string | null>(null);
  const [check, setCheck] = useState(false);
  const [openSpoiler, setOpenSpoiler] = useState<string | null>(null);
  const [calculate, setCalculate] = useState<number | null>(null);
  const [openPhone, setOpenPhone] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [cleaner, setCleaner] = useState(false);
  const [mask, setMask] = useState("+7(9__)___-__-__");
  const [cursorPosition, setCursorPosition] = useState(5.2);
  const [label, setLabel] = useState(false);
  const [openCalculate, setOpenCalculate] = useState(false);
  const [scrolls, setScrolls] = useState(0);
  const [images, setImages] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [openSpisok, setOpenSpisok] = useState(false);
  const [openSpisokOne, setOpenSpisokOne] = useState(false);
  const [openSpisokTwo, setOpenSpisokTwo] = useState(false);
  const [openSpisokThree, setOpenSpisokThree] = useState(false);
  const [openSpisokFour, setOpenSpisokFour] = useState(false);
  const [openSpisokFive, setOpenSpisokFive] = useState(false);
  const [openSpisokSix, setOpenSpisokSix] = useState(false);
  const [openSpisokOfer, setOpenSpisokOfer] = useState(false);
  const [openSpisokPolitic, setOpenSpisokPolitic] = useState(false);

  const api = `https://402.m-dels.ru/order`;

  // загружаем для слайдера все изображения
  const getImage = (): string[] => {
    return [SliderOne, SliderTwo, SliderThree, SliderFour];
  };

  // определяем стиль для индекса изображения (активный, следующий и предыдущий, остальные скрываем)
  const getPositionClass = (index: number): string => {
    if (index === currentIndex) return style.active;
    if (index === (currentIndex + 1) % images.length) return style.next;
    if (index === (currentIndex - 1 + images.length) % images.length) return style.prev;
    return style.hidden;
  };

  // переключаме слайдер вперед
  const handleClickForward = () => {
    if (isAnimating) return; // Если анимация идет, ничего не делаем
    setIsAnimating(true);

    // Сбрасываем флаг анимации после завершения
    setTimeout(() => {
      setIsAnimating(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500);
  };

  // переключаме слайдер назад
  const handleClickBack = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }, 500);
  };

  // функция для установки типа помещения
  const handleHouse = (name: string) => {
    switch(name) {
      case "office": setHouse(name); break;
      case "flat": setHouse(name); break;
      case "point": setHouse(name); break;
    }
    setServiceValue(null);
    setServiceValueType(null);
  };

  // функция для установки площади
  const handleSquare = (value: number) => {
    switch(value) {
      case 40: setSquare(value.toString()); break;
      case 50: setSquare(value.toString()); break;
      case 60: setSquare(value.toString()); break;
      case 70: setSquare(value.toString()); break;
      case 80: setSquare(value.toString()); break;
      case 90: setSquare(value.toString()); break;
    }
  };

  // функция для отображения площади
  const handleChangeSquare = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result = event.target.value.replace(/\D/g, '');
    if (parseInt(event.target.value, 10) < 1) {
      event.target.value = '1';
      result = event.target.value;
    } else if (parseInt(event.target.value, 10) > 9999) {
      event.target.value = '9999';
      result = event.target.value;
    } else {
      setSquare(result);
    }
  };

  // функция для установки услуги
  const handleService = (event: string) => {
    setServiceValue(event);
    setService(false);
  };

  // функция для установки типа услуги
  const handleServiceType = (event: string) => {
    setServiceValueType(event);
    setServiceDescript(false);
  };

  // функция для ввода телефона
  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result = event.target.value.replace(/\D/g, '');
    if (result.length > 0) {
      setMask(`+7(9${result[0]}_)___-__-__`);
      setCursorPosition(6);
    }
    if (result.length > 1) {
      setMask(`+7(9${result.slice(0, 2)})___-__-__`);
      setCursorPosition(7);
    }
    if (result.length > 2) {
      setMask(`+7(9${result.slice(0, 2)})${result[2]}__-__-__`);
      setCursorPosition(8.5);
    }
    if (result.length > 3) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 4)}_-__-__`);
      setCursorPosition(9.5);
    }
    if (result.length > 4) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-__-__`);
      setCursorPosition(10.5);
    }
    if (result.length > 5) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result[5]}_-__`);
      setCursorPosition(11.5);
    }
    if (result.length > 6) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-__`);
      setCursorPosition(12.5);
    }
    if (result.length > 7) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result[6]}_`);
      setCursorPosition(13.7);
    }
    if (result.length > 8) {
      setMask(`+7(9${result.slice(0, 2)})${result.slice(2, 5)}-${result.slice(5, 7)}-${result.slice(7, 9)}`);
      setCursorPosition(14.7);
    }
    if (result.length === 0) {
      setMask(`+7(9__)___-__-__`);
      setCursorPosition(5.2);
    }

    event.target.value = result.slice(0, 9);
    
    setValue('phone', result.slice(0, 9));
  };

  // функция для вкл/выкл принятия пользовательского соглашения
  const handleCheck = () => {
    setCheck(!check);
  };

  // функция для переключения спойлеров
  const handleSpoiler = (name: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (openSpoiler === name) {
      setOpenSpoiler(null);
      dispatch(spoilersReset());
    } else {
      setOpenSpoiler(name);
      switch(name) {
        case "spoilerOne": dispatch(spoilerOneTrue()); break;
        case "spoilerTwo": dispatch(spoilerTwoTrue()); break;
        case "spoilerThree": dispatch(spoilerThreeTrue()); break;
        case "spoilerFour": dispatch(spoilerFourTrue()); break;
        case "spoilerFive": dispatch(spoilerFiveTrue()); break;
        default: break;
      }
    }
  };

  // открываем модалку для звонка
  const handlePhones = () => {
    setOpenPhone(true);
    setCleaner(false);
  };

  // открываем модалку для клинера
  const handleCleaner = () => {
    setOpenPhone(true);
    setCleaner(true);
  };

  // функция для перехода в Whats App
  const handleWa = () => {
    const url = "https://api.whatsapp.com/message/232ZXS3OASO5P1?autoload=1&app_absent=0";
    window.open(url, '_blank'); 
  };

  // функция для перехода в Telegram
  const handleTg = () => {
    const url = "https://t.me/LaStik444";
    window.open(url, '_blank'); 
  };

  // функция для закрытия модального окна отображающего услуги, оферту и политику соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokOne(false);
    setOpenSpisokTwo(false);
    setOpenSpisokThree(false);
    setOpenSpisokFour(false);
    setOpenSpisokFive(false);
    setOpenSpisokSix(false);
    setOpenSpisokOfer(false);
    setOpenSpisokPolitic(false);
  }

  // функция для снятия фокуса с ввода телефона и закрытия выпадающих списков
  const handleLabelAndChangeClose = () => {
    setLabel(false);
    setService(false);
    setServiceDescript(false);
  }

  // Отправка формы
  const onSubmit = async (formData: TFormValues) => {
    if (!watch('name') || !watch('phone') || watch('phone').length !== 9) {
      return null;
    } else if(!check) {
      return null;
    } else {
      try {
        const dataToSend = {
          'order-info': {
            phone: formData.phone = mask,
            name: formData.name,
            type: formData.type || '',
          }
        };
  
        await Axios.post(api, dataToSend)
          .then((response) => {
            console.log(response);
          });
      } catch (error) {
        console.log('Error:', error);
      }
    }
  };

  // отображения данных работы калькулятора
  useEffect(() => {
    const calculateResult = Calculate({house, serviceValue, serviceValueType, square});
    setCalculate(calculateResult);
  }, [house, serviceValue, serviceValueType, square]);

  // считываем скролл по оси Y
  useEffect(() => {
    const handleScroll = () => {
      setScrolls(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, []);


  // Устанавливаем пути к изображениям в стейт для доступа к ним в JSX
  useEffect(() => {
    const imageSources = getImage();
    setImages(imageSources);
  }, []);

  // Меняем каждые 5 секунд слайд
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === 3) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => (prevIndex + 1));
      }
      
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);
  
  return (
    <div className={style.main} onClick={handleLabelAndChangeClose}>
      <div className={style.sliders}>
        <div className={scrolls > 0 ? style.tops : style.top}>
          <Logo className={style.logo} />
          <Phone className={style.phones} />
          <div className={style.phone}>8 (8182) 43-32-10</div>
          <button className={style.btn} type="button" onClick={() => setOpenPhone(true)}>Заказать звонок</button>
          <button className={style.btns} type="button">
            <LogIn className={style.logIn} />
          </button>
        </div>
        {images.map((item, index) => {
          const position = getPositionClass(index);
          return (
            <img
              key={index}
              className={`${style.slideImg} ${position}`}
              src={item}
              alt={`Slide number ${index + 1}`}
              />
          );
        })}
        {currentIndex === 0 ? (
          <>
            <div className={style.slideOneTitle}>Все меньше шансов<br />что-нибудь сделать сегодня</div>
            <div className={style.slideOneCaption}>и все обиднее за то, чего не успел вчера</div>
          </>
        ) : currentIndex === 1 ? (
          <>
            <div className={style.slideTwoTitleOne}>
              <span>Человек на уборку тратит <span className={style.text}>300 ЧАСОВ</span> в год</span>
            </div>
            <div className={style.slideTwoTitleTwo}>
              <span>За жизнь примерно <span className={style.text}>18000 часов</span> </span>
            </div>
            <div className={style.slideTwoTitleThree}>
              <span>Это соответствует <br/><span className={style.text}>2 годам</span> счастливой жизни</span>
            </div>
          </>
        ) : currentIndex === 2 ? (
          <>
            <div className={style.slideThreeTitle}>Мы знаем, что потраченного времени не вернуть</div>
            <div className={style.slideThreeCaption}>Не тратьте его на ненужные вещи</div>
          </>
        ) : currentIndex === 3 ? (
          <div className={style.slideFourTitle}>Доверьте уборку нам!</div>
        ) : null}
        <button type="button" className={style.btnWapp} onClick={handleWa}>
          <Wapp className={style.wapp} />
        </button>
        <button type="button" className={style.btnTg} onClick={handleTg}>
          <Tg className={style.tg} />
        </button>
        <div className={style.slider}>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(0)}>
            {currentIndex === 0 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(1)}>
            {currentIndex === 1 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(2)}>
            {currentIndex === 2 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button>
          <button className={style.btn} type="button" onClick={() => setCurrentIndex(3)}>
            {currentIndex === 3 ? <RadioTrue /> : <RadioFalse className={style.false} />}
          </button> 
        </div>
        <button className={style.btnBack} type="button" onClick={handleClickBack}>
          <BtnBack />
        </button>
        <button className={style.btnForward} type="button" onClick={handleClickForward}>
          <BtnForward />
        </button>
      </div>
      <div className={style.pays}>
        <div className={style.left}>
          <div className={style.title}>Уборка офиса</div>
          <div className={style.text}>от 1500 ₽</div>
          <button className={style.btn} type="button" onClick={() => setOpenService(true)}>
            <span className={style.btnText}>Заказать услугу</span>
          </button>
        </div>
        <div className={style.center}>
          <div className={style.title}>Уборка квартиры</div>
          <div className={style.text}>от 2000 ₽</div>
          <button className={style.btn} type="button" onClick={() => setOpenService(true)}>
            <span className={style.btnText}>Заказать услугу</span>
          </button>
        </div>
        <div className={style.right}>
          <div className={style.title}>Уборка торговой точки</div>
          <div className={style.text}>от 1800 ₽</div>
          <button className={style.btn} type="button" onClick={() => setOpenService(true)}>
            <span className={style.btnText}>Заказать услугу</span>
          </button>
        </div>
      </div>
      <div className={style.calculates}>
        <button className={style.btnWa} type="button" onClick={handleWa}>
          <Wapp className={style.wapp} />
        </button>
        <button className={style.btnTg} type="button" onClick={handleTg}>
          <Tg className={style.tg} />
        </button>
        <div className={style.title}>Подбери уборку <br />для себя</div>
        <div className={style.caption}>Не удалось подобрать, мы обязательно поможем</div>
        <div className={style.house}>
          <button type="button" className={`${style.office} ${house === 'office' ? style.active : null}`} onClick={() => handleHouse("office")}>
            Офис
          </button>
          <button type="button" className={`${style.flat} ${house === 'flat' ? style.active : null}`} onClick={() => handleHouse("flat")}>
            Квартира
          </button>
          <button type="button" className={`${style.point} ${house === 'point' ? style.active : null}`} onClick={() => handleHouse("point")}>
            Торговая точка
          </button>
        </div>
        <div className={style.square}>
          <div className={style.text}>Площадь квартиры</div>
          <button className={`${style.squareFirst} ${square === '40' ? style.active : null}`} onClick={() => handleSquare(40)}>40</button>
          <button className={`${style.squareValue} ${square === '50' ? style.active : null}`} onClick={() => handleSquare(50)}>50</button>
          <button style={{ width: '73px' }} className={`${style.squareValue} ${square === '60' ? style.active : null}`} onClick={() => handleSquare(60)}>60</button>
          <button className={`${style.squareValue} ${square === '70' ? style.active : null}`} onClick={() => handleSquare(70)}>70</button>
          <button style={{ width: '74px' }} className={`${style.squareValue} ${square === '80' ? style.active : null}`} onClick={() => handleSquare(80)}>80</button>
          <button style={{ width: '74px' }} className={`${style.squareValue} ${square === '90' ? style.active : null}`} onClick={() => handleSquare(90)}>90</button>
          <div className={style.squareLast}>
            <input className={style.squareInput} type="text" value={square !== null ? square : '____'} onChange={handleChangeSquare}/>
          </div>
        </div>
        <button
          type="button" 
          className={style.select} 
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            setService(prevState => !prevState);
            e.stopPropagation();
          }}
        >
          <span>{serviceValue ? serviceValue : "Выберите услугу"}</span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={service && house ? '' : style.selectHidden}>
          <button type="button" className={style.handleOptionOne} onClick={() => handleService("Поддерживающая уборка")}>
            <span>Поддерживающая уборка</span>
          </button>
          <button type="button" className={style.handleOptionTwo} onClick={() => handleService("Генеральная")}>
            <span>Генеральная</span>
          </button>
          <button type="button" className={style.handleOptionThree} onClick={() => handleService("Послестроительная")}>
            <span>Послестроительная</span>
          </button>
        </div>
        <button 
          type="button" 
          className={style.selects}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            serviceValue ? setServiceDescript(prevState => !prevState) : setServiceDescript(false);
            e.stopPropagation();
          }}
        >
          <span>{serviceValueType ? serviceValueType : "Выберите услуги"}</span>
          <span className={style.arrow}><ArrowDown /></span>
        </button>
        <div className={serviceDescript ? '' : style.selectsHidden}>
          {house === "office" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "office" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "office" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "flat" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "flat" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.standarts} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
              <button type="button" className={style.accocial} onClick={() => handleServiceType("Ассоциальная")}>
                Ассоциальная
              </button>
              <button type="button" className={style.party} onClick={() => handleServiceType("Последствия вечеринки")}>
                Последствия вечеринки
              </button>
              <button type="button" className={style.sells} onClick={() => handleServiceType("Предпродажная")}>
                Предпродажная
              </button>
            </>
          ) : house === "flat" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "point" && serviceValue === "Поддерживающая уборка" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "point" && serviceValue === "Послестроительная" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : house === "point" && serviceValue === "Генеральная" ? (
            <>
              <button type="button" className={style.standart} onClick={() => handleServiceType("Стандарт")}>
                <span>Стандарт</span>
              </button>
            </>
          ) : null}
        </div>
        <div className={style.cena}>Цена</div>
        <div className={style.rouble}>от {calculate ? calculate.toLocaleString('ru-RU') : '0 000'} ₽</div>
        <button className={style.order} type="button" onClick={() => setOpenCalculate(true)}>
          Заказать
        </button>
      </div>
      <div className={style.previews}>
        <div className={style.title}>LaStik</div>
        <div className={style.caption}>Обслуживание, которого вы заслуживаете!</div>
        <Frame style={{ position: 'absolute', top: '160px', left: '717px' }} />
        <div className={style.clean}>Обученные клинеры</div>
        <Frame style={{ position: 'absolute', top: '248px', left: '717px' }} />
        <div className={style.cleans}>Профессиональное оборудование</div>
        <Frame style={{ position: 'absolute', top: '380px', left: '717px' }} />
        <div className={style.wash}>Безопасные моющие средства</div>
        <Frame style={{ position: 'absolute', top: '512px', left: '717px' }} />
        <div className={style.standarts}>Высокие стандарты обслуживания</div>
      </div>
      <div className={style.marketings}>
        <div className={style.left}>
          <div className={style.number}>7</div>
          <div className={style.text}>лет на рынке</div>
        </div>
        <div className={style.center}>
          <div className={style.number}>1731</div>
          <div className={style.text}>довольных клиента</div>
        </div>
        <div className={style.right}>
          <div className={style.number}>1,7</div>
          <div className={style.text}>млн. чистых квадратных метров</div>
        </div>
      </div>
      <form className={style.orders} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.title}>Заказать уборку</div>
        <input className={style.name} type="text" placeholder="Имя" {...register('name')} />
        <label 
          className={style.phone} 
          htmlFor="phone" 
          onClick={(e: React.MouseEvent<HTMLLabelElement>) =>{
            e.stopPropagation();
            setLabel(true);
          }}
        >
          {mask}
          <input
            id="phones"
            className={style.phones}
            type="text"
            {...register('phone', {onChange: handlePhone})}
          />
          <span className={label ? style.cursor : style.cursorHidden} style={{ left: `${cursorPosition * 10}px` }} />
        </label>
        <button className={style.btns} type="submit">Заказать услугу</button>
        <div className={style.text}>
          <span>Принимаю <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOfer(true)}}>пользовательское соглашение</span>. Соглашаюсь на <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokPolitic(true)}}>обработку персональных данных</span>.</span>
        </div>
        <button type="button" className={style.check} onClick={handleCheck}>
          {check? <Check className={style.checks} /> : null}
        </button>
      </form>
      <div className={style.faqs}>
        <div className={style.title}>Частые вопросы</div>
        <div className={style.spoilers}>
          <details className={style.spoilerOne} open={openSpoiler === 'spoilerOne'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerOne', event)}>Как сделать заказ на уборку?</summary>
            {spoilerOne ?
              <div className={style.spoilerText}>Нажмите значок WA или Telegram, напишите тип объекта, вид уборки (генеральная, поддерживающая или послестроительная) напишите желаемую дату и время. При необходимости ответьте на дополнительные вопросы менеджера. Также вы можете оставить запрос на обратный звонок.</div>
            : null}
          </details>
          <details className={style.spoilerTwo} open={openSpoiler === 'spoilerTwo'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerTwo', event)}>Я могу оставить клинера одного в квартире??</summary>
            {spoilerTwo ?
              <div className={style.spoilerText}>Да, можете оставить клинера в квартире одного, при этом за полчаса до окончания, он с Вами свяжется.</div>
            : null}
          </details>
          <details className={style.spoilerThree} open={openSpoiler === 'spoilerThree'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerThree', event)}>Есть ли скидки на уборки?</summary>
            {spoilerThree ?
              <div className={style.spoilerText}>Да, при первом заказе действует скидка по кодовому слову #ЧИСТОЧИСТО</div>
            : null}
          </details>
          <details className={style.spoilerFour} open={openSpoiler === 'spoilerFour'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFour', event)}>Нужно ли что-то приготовить для клинера?</summary>
            {spoilerFour ?
              <div className={style.spoilerText}>Все моющие средства и инвентарь клинер привозит с собой, но с вашего позволения и при необходимости может воспользоваться Вашим пылесосом (в случае поддерживающей уборки).</div>
            : null}
          </details>
          <details className={style.spoilerFive} open={openSpoiler === 'spoilerFive'}>
            <summary className={style.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFive', event)}>Что входит в уборку?</summary>
            {spoilerFive ?
              <div className={style.spoilerText}>В нижней части сайта расположена информация о составе услуг, при клике на соответствующую услугу, вы сможете ознакомится с информацией о составе услуг.</div>
            : null}
          </details>
        </div>
      </div>
      <div className={style.footers}>
        <Logo className={style.logo} />
        <div className={style.lastik}>Lastik</div>
        <div className={style.textL}>
          <span><span className={style.link}>Помощь</span><br/><span className={style.link} onClick={handleCleaner}>Стать клинером</span></span>
        </div>
        <div className={style.clean}>Уборка</div>
        <div className={style.textC}>
          <span>
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOne(true)}}>Поддерживающая</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokTwo(true)}}>Генеральная-стандарт</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokThree(true)}}>Генеральная-ассоциальная</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokFour(true)}}>Генеральная-после вечеринки</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokFive(true)}}>Генеральная-предпродажная</span><br />
            <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokSix(true)}}>Послестроительная</span>
          </span>
          </div>
        <div className={style.phone}>Тел: 8 (8182) 43-32-10</div>
        <button type="button" className={style.btn} onClick={handlePhones}>Заказать звонок</button>
        <div className={style.contract}>
          <span>Используя информационный ресурс https://lastik.vip Вы соглашаетесь с условиями <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokOfer(true)}}>Оферты</span> и соглашаетесь с <span className={style.link} onClick={() => {setOpenSpisok(true); setOpenSpisokPolitic(true)}}>Политикой конфиденциальности</span></span>
        </div>
        <div className={style.lastiks}>© 2024, ООО «КАКТУС»</div>
      </div>
      <PhoneModal open={openPhone} onClose={() => setOpenPhone(false)} cleaner={cleaner} />
      <ServiceModal open={openService} onClose={() => setOpenService(false)} />
      <ServiceCalckModal
        open={openCalculate}
        onClose={() => setOpenCalculate(false)}
        house={house}
        square={square}
        serviceType={serviceValue}
      />
      <SpisokModal 
        open={openSpisok}
        one={openSpisokOne}
        two={openSpisokTwo}
        three={openSpisokThree}
        four={openSpisokFour}
        five={openSpisokFive}
        six={openSpisokSix}
        ofer={openSpisokOfer}
        politic={openSpisokPolitic}
        onClose={handleSpisokClose} 
      />
    </div>
  );
};

export default MainPage;
