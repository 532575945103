import * as React from "react";
import {CurrentContext} from "./AuthContext";
import {Role, User} from "./user";
import auth from "../auth";
import {AppDispatch} from "../../redux/store";
import {loadingSlice} from "../../redux/reducers/loading";
import {useCallback} from "react";
import {useAppDispatch} from "../../hooks/redux";

type UserProviderProps = {
    children: React.ReactNode
}

export const AuthContextProvider = (props: UserProviderProps) => {
    const [user, setUser] = React.useState<User>();
    const dispatch : AppDispatch = useAppDispatch();

    const ensureUser = useCallback(async () : Promise<boolean> => {
        if (user) {
            return true;
        }

        try {
            const isSuccess = await auth.ensureSignedIn()

            dispatch(loadingSlice.actions.setHint("Запрос токенов"))
            if (!isSuccess) {
                setUser(new User());
                return false;
            }

            const response = await auth.apiClient.get("/profile")
            setUser(new User(Role[response.data.role.toLocaleUpperCase()]));
            return true;
        }
        catch (e) {
            setUser(new User());
            return false;
        }
    }, []);

    return (
        <CurrentContext.Provider value={{user, setUser, ensureUser}}>
            {props.children}
        </CurrentContext.Provider>
    )
};

export default AuthContextProvider