import styled from "styled-components";

/**
 * @Composable
 * Поле ввода
 * @extends input
 */
const ScInput = styled.input`
    border: 0.5px solid ${props => props.theme.colors.secondary}aa;  /*'aa' - is alpha channel of hex*/
    border-radius: ${props => props.theme.rounded.normal};

    min-width: 190px;
    padding: 0.85em calc(2.5% + 10px);

    outline: none;
    width: auto;

    font-size: ${props =>props.theme.typography.fontSizes.normal};

    &::placeholder {
        color: #D8D8D8;
    }
    
    &[aria-invalid="true"] {
        border: 1px solid red;
    }
    
    &.hide-arrows {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
`

export default ScInput;