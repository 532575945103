import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import calculate, {
    ICalculationResult,
    ICalculatorFieldsState,
    preferDate,
    RoomType,
    ServiceType
} from "../../../api/calculator";
import dateToUTCString from "../../../api/convertors/dateToUTCString";

interface ICalculatorSliceState {
    calculationResult: ICalculationResult,
    fieldsState: ICalculatorFieldsState,
}


const initialState: ICalculatorSliceState = {
    calculationResult: {
        additionalsSum: 0,
        mainSum: 0,
        maxAvailablePointsValue : 0,
        pointsDiscountValue : 0,
        primarySum : 0,
        promoDiscountValue : 0,
        promoQuantifier : 0,
        totalSum : 0
    },
    fieldsState: {
        selectedRoom: RoomType.Apartment,
        selectedSquare: 30,
        selectedService: ServiceType.General,
        selectedSubservice : "default",
        selectedDateTimeUTCString: dateToUTCString(preferDate(new Date())),
        checkedAdditionals: [],
        typedPromo: "",
        promoQuantifier: 1,
        usedPoints: 0
    }
}

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    reducers: {
        updateCalculationResult: (state) => {
            state.calculationResult = calculate(state.fieldsState)
        },
        updateFieldsState: (state, action: PayloadAction<Partial<ICalculatorFieldsState>>) => {
            state.fieldsState = {
                ...state.fieldsState,
                ...action.payload
            }

            calculatorSlice.caseReducers.updateCalculationResult(state)
        },
        checkAdditional: (state, action: PayloadAction<string>) => {
            state.fieldsState.checkedAdditionals = [...state.fieldsState.checkedAdditionals, action.payload];

            calculatorSlice.caseReducers.updateCalculationResult(state)
        },
        uncheckAdditional: (state, action: PayloadAction<string>) => {
            state.fieldsState.checkedAdditionals = state.fieldsState.checkedAdditionals.filter(element => element !== action.payload);

            calculatorSlice.caseReducers.updateCalculationResult(state)
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateCalculationResult, updateFieldsState, checkAdditional, uncheckAdditional } = calculatorSlice.actions
export default calculatorSlice.reducer