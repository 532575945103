type TCalculate = {
  house: string;
  serviceValue: string | null;
  serviceValueType: string | null;
  square: string | null;
};

export const Calculate = ({house, serviceValue, serviceValueType, square}: TCalculate): number | null  => {
  let result: number | null = null;

  switch(house) {
    case "office":
      switch(serviceValue) {
        case "Поддерживающая уборка":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [1350, 1600, 2000, 2280, 2450, 2800, 3150];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = 1350;
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 32);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;  
          }
        break;
        case "Генеральная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [6600, 8600, 10750, 12600, 14700, 16400, 18450];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (6600);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 200);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;
          }
        break;
        case "Послестроительная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [7500, 9600, 11750, 14100, 16100, 18000, 19800];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (7500);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 200);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              }
            break; 
          }
        break;
      }
    break;
    case "point":
      switch(serviceValue) {
        case "Поддерживающая уборка":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [1200, 1600, 2000, 2220, 2590, 2800, 3150];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (1200);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 32);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;  
          }
        break;
        case "Генеральная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [5700, 7400, 9250, 10800, 12250, 14000, 15300];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (5700);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 165);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;  
          }
        break;
        case "Послестроительная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [8250, 10800, 13500, 15900, 18200, 20400, 22950];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (8250);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 250);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;  
          }
        break;
      }
    break;
    case "flat":
      switch(serviceValue) {
        case "Поддерживающая уборка":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [2850, 3800, 4750, 5700, 6650, 7600, 8550];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (2850);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 90);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;  
          }
        break;
        case "Генеральная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [11100, 13800, 15750, 18300, 20300, 24400, 27450];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (11100);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 300);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;
            case "Ассоциальная":
              const paysA: number[] = [33000, 40800, 48500, 55800, 63700, 68000, 72900];
              const intSquareA = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquareA = new Array(10).fill(0).map((_, index) => index + intSquareA);
              if (parseInt(square) < 30) {
                result = (33000);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 750);
              } else {
                switch(intSquareA) {
                  case 30:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[0]);
                    } else {
                      result = (paysA[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[1]);
                    } else {
                      result = (paysA[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[2]);
                    } else {
                      result = (paysA[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[3]);
                    } else {
                      result = (paysA[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[4]);
                    } else {
                      result = (paysA[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquareA[Math.floor(arraySquareA.length / 2)]) {
                      result = (paysA[5]);
                    } else {
                      result = (paysA[6]);
                    }
                  break;
                  case 90:
                    result = (paysA[6]);
                  break;
                }
              } 
            break;
            case "Последствия вечеринки":
              const paysP: number[] = [15600, 18800, 23000, 25800, 29400, 33600, 37800];
              const intSquareP = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquareP = new Array(10).fill(0).map((_, index) => index + intSquareP);
              if (parseInt(square) < 30) {
                result = (15600);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 400);
              } else {
                switch(intSquareP) {
                  case 30:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[0]);
                    } else {
                      result = (paysP[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[1]);
                    } else {
                      result = (paysP[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[2]);
                    } else {
                      result = (paysP[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[3]);
                    } else {
                      result = (paysP[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[4]);
                    } else {
                      result = (paysP[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquareP[Math.floor(arraySquareP.length / 2)]) {
                      result = (paysP[5]);
                    } else {
                      result = (paysP[6]);
                    }
                  break;
                  case 90:
                    result = (paysP[6]);
                  break;
                }
              } 
            break;
            case "Предпродажная":
              const paysS: number[] = [9000, 11800, 14500, 17100, 19600, 22000, 24300];
              const intSquareS = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquareS = new Array(10).fill(0).map((_, index) => index + intSquareS);
              if (parseInt(square) < 30) {
                result = (9000);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 250);
              } else {
                switch(intSquareS) {
                  case 30:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[0]);
                    } else {
                      result = (paysS[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[1]);
                    } else {
                      result = (paysS[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[2]);
                    } else {
                      result = (paysS[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[3]);
                    } else {
                      result = (paysS[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[4]);
                    } else {
                      result = (paysS[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquareS[Math.floor(arraySquareS.length / 2)]) {
                      result = (paysS[5]);
                    } else {
                      result = (paysS[6]);
                    }
                  break;
                  case 90:
                    result = (paysS[6]);
                  break;
                }
              } 
            break;
          }
        break;
        case "Послестроительная":
          switch(serviceValueType) {
            case "Стандарт":
              const pays: number[] = [12000, 15800, 19500, 23100, 26600, 30000, 33300];
              const intSquare = Math.floor(parseInt(square) / 10) * 10; 
              const arraySquare = new Array(10).fill(0).map((_, index) => index + intSquare);
              if (parseInt(square) < 30) {
                result = (12000);
              } else if (parseInt(square) > 90) {
                result = (parseInt(square) * 350);
              } else {
                switch(intSquare) {
                  case 30:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[0]);
                    } else {
                      result = (pays[1]);
                    }
                  break;
                  case 40:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[1]);
                    } else {
                      result = (pays[2]);
                    }
                  break;
                  case 50:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[2]);
                    } else {
                      result = (pays[3]);
                    }
                  break;
                  case 60:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[3]);
                    } else {
                      result = (pays[4]);
                    }
                  break;
                  case 70:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[4]);
                    } else {
                      result = (pays[5]);
                    }
                  break;
                  case 80:
                    if (parseInt(square) < arraySquare[Math.floor(arraySquare.length / 2)]) {
                      result = (pays[5]);
                    } else {
                      result = (pays[6]);
                    }
                  break;
                  case 90:
                    result = (pays[6]);
                  break;
                }
              } 
            break;
          }
        break;
      }
    break;
  };

  return result;
};
