import {Colors, FontSizes} from "../../utils/globals";
import {ITextProps} from "./ScText";
import styled, {css} from "styled-components";
import {cssTransitions} from "../../utils/cssShortcuts";


export interface IButtonProps extends ITextProps {
    backgroundColor?: Colors
}

const ButtonPropsDefaults : IButtonProps = {
    color: Colors.primaryContainer,
    backgroundColor: Colors.primary,
    fontSize: FontSizes.normal
}

export const ScButton = styled.button<IButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    ${({ color, backgroundColor }) => css`
        color: ${color || ButtonPropsDefaults.color};
        background-color: ${backgroundColor || ButtonPropsDefaults.backgroundColor};
    `}

    ${({ fontSize }) => css`
        font-size: ${fontSize || ButtonPropsDefaults.fontSize};
    `}

    ${cssTransitions.default};
    
    border-radius: ${props => props.theme.rounded.normal};
    border: none;
    
    cursor: pointer;
    
    padding: 1em;
    outline: inherit;
    white-space: nowrap;
    
    &[disabled] {
        border: 1px solid ${props => props.theme.colors.tertiaryVariant} !important;
        color: ${props => props.theme.colors.tertiaryVariant};
        background-color: ${props => props.theme.colors.surface};

        cursor: default;
    }
`

/**
 * ![ScFilledButton](https://i.ibb.co/BZ8v586/photo-2024-09-03-19-14-53.jpg)
 * @Composable
 * Заполненная кнопка
 * @extends ScButton
 */
export const ScFilledButton = styled(ScButton)`
    ${({ color, backgroundColor }) => css`
        color: ${color || ButtonPropsDefaults.color};
        background-color: ${backgroundColor || ButtonPropsDefaults.backgroundColor};
        border: 1px solid ${color || ButtonPropsDefaults.backgroundColor};
        
        @media (hover: hover) {
            &:enabled:hover {
                color: ${backgroundColor || ButtonPropsDefaults.backgroundColor};
                background-color: ${color || ButtonPropsDefaults.color};
            }
        }
        
        @media (hover: none) {
            &:enabled:active {
                color: ${backgroundColor || ButtonPropsDefaults.backgroundColor};
                background-color: ${color || ButtonPropsDefaults.color};
            }
        }
    `}
`


/**
 * @Composable
 * Тональная кнопка
 * @extends ScButton
 */
export const ScTonalButton = styled(ScButton)`
    ${({ color, backgroundColor }) => css`
        color: ${props => color || props.theme.colors.tertiary};
        background-color: ${props => backgroundColor || props.theme.colors.secondaryContainer};
        border: 1px solid ${props => backgroundColor || props.theme.colors.secondaryContainer};;
        
        @media (hover: hover) {
            &:enabled:hover {
                border-color: ${props => backgroundColor || props.theme.colors.tertiary};
            }
        }
        
        @media (hover: none) {
            &:enabled:active {
                border-color: ${props => backgroundColor || props.theme.colors.tertiary};
            }
        }
    `}
`


/**
 * @Composable
 * Очерченная кнопка
 * @extends ScButton
 */
export const ScOutlinedButton = styled(ScButton)`
    ${({ color, backgroundColor }) => css`
        color: ${color || ButtonPropsDefaults.backgroundColor};
        background-color: ${backgroundColor || ButtonPropsDefaults.color};
        border: 1px solid ${color || ButtonPropsDefaults.backgroundColor};

        @media (hover: hover) {
            &:enabled:hover {
                border-width: 2px;
            }
        }

        @media (hover: none) {
            &:enabled:active {
                border-left-color: transparent;
                border-right-color: transparent;
                border-radius: 0;
            }
        }
    `}
`


/**
 * @Composable
 * Кнопка-иконка без стилей
 * @extends ScButton
 */
export const ScIconButton = styled(ScButton)`
    background-color: unset;
    color: unset;
    padding: unset;
`

/**
 * @Composable
 * Промежуточный предок кнопки для описания стилевых кнопок-иконок
 * @abstract [!] Не вызывать напрямую, компонент абстрактен
 * @extends ScButton
 */
const ScParentIconButton = styled(ScButton)`
    > * {
        ${cssTransitions.default};
    }

    @media (hover: hover) {
        &:enabled:hover > * {
            transform: scale(1.25);
        }
    }

    @media (hover: none) {
        &:enabled:active > * {
            transform: scale(1.25);
        }
    }
`

/**
 * @Composable
 * Заполненная кнопка-иконка
 * @extends ScParentIconButton
 */
export const ScFilledIconButton = styled(ScParentIconButton)`
    ${({ color, backgroundColor }) => css`
        color: ${color || ButtonPropsDefaults.color};
        background-color: ${backgroundColor || ButtonPropsDefaults.backgroundColor};
        border: 1px solid ${color || ButtonPropsDefaults.backgroundColor};
    `};
`


/**
 * @Composable
 * Очерченная кнопка-иконка
 * @extends ScParentIconButton
 */
export const ScOutlinedIconButton = styled(ScParentIconButton)`
    ${({ color, backgroundColor }) => css`
        color: ${color || ButtonPropsDefaults.backgroundColor};
        background-color: ${backgroundColor || ButtonPropsDefaults.color};
        border: 1px solid ${color || ButtonPropsDefaults.backgroundColor};
    `};
`