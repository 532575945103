// Перечисление помещение
export enum RoomType {
    Office      = "office",     // Офис
    Apartment   = "apartment",  // Квартира
    Market      = "market"      // Торговый центр
}

// Перечисление услуга
export enum ServiceType {
    Support     = "support",    // поддерживающая
    General     = "general",    // генеральная
    Build       = "build"       // послестроительная
}

export interface ITextable {
    id?: string,
    text: string
}

export interface IListable<T> extends ITextable {
    list: T[]
}

export interface ISquareCalculable {
    fun: (squareValue: number) => number
}

export interface IAdditional extends ITextable, ISquareCalculable {}

export interface ISubservice extends IAdditional{
    additional?: IAdditional[]
}

const Logic : IListable<IListable<ISubservice>>[] = [
    {
        id: "apartment",
        text: "Квартира",
        list: [
            {
                id: "general",
                text: "Генеральная",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 370;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 345;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 315;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 305;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 290;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 305;
                            } else if (squareValue < 90) {
                                return 90 * 305;
                            } else {
                                return squareValue * 300;
                            }
                        },
                        additional: [
                            {
                                text: "Мытье окон снаружи",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 1200;
                                    } else if (squareValue < 40 + 5) {
                                        return 1400;
                                    } else if (squareValue < 50 + 5) {
                                        return 1500;
                                    } else if (squareValue < 60 + 5) {
                                        return 1700;
                                    } else if (squareValue < 70 + 5) {
                                        return 1900;
                                    } else if (squareValue < 80 + 5) {
                                        return 2500;
                                    } else if (squareValue < 90) {
                                        return 3000;
                                    } else {
                                        return 5000;
                                    }
                                }
                            },
                            {
                                text: "Мытье окон на балконе внутри",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 2500;
                                    } else if (squareValue < 40 + 5) {
                                        return 2500;
                                    } else if (squareValue < 50 + 5) {
                                        return 2500;
                                    } else if (squareValue < 60 + 5) {
                                        return 2500;
                                    } else if (squareValue < 70 + 5) {
                                        return 2500;
                                    } else if (squareValue < 80 + 5) {
                                        return 4000;
                                    } else if (squareValue < 90) {
                                        return 6000;
                                    } else {
                                        return 10000;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: "assoc",
                        text: "Ассоциальная",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 1100;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 1020;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 970;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 930;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 910;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 850;
                            } else if (squareValue < 90) {
                                return 90 * 810;
                            } else {
                                return squareValue * 750;
                            }
                        }
                    },
                    {
                        id: "party",
                        text: "Последствия вечеринки",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 520;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 470;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 460;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 430;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 420;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 420;
                            } else if (squareValue < 90) {
                                return 90 * 420;
                            } else {
                                return squareValue * 400;
                            }
                        },
                        additional: [
                            {
                                text: "Квартира находится вне черты города",
                                fun: (squareValue: number) => {
                                    return 2500;
                                }
                            }
                        ]
                    },
                    {
                        id: "presold",
                        text: "Предпродажная",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 300;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 295;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 290;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 285;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 280;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 275;
                            } else if (squareValue < 90) {
                                return 90 * 270;
                            } else {
                                return squareValue * 250;
                            }
                        },
                        additional: [
                            {
                                text: "Мытье балкона",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 2500;
                                    } else if (squareValue < 40 + 5) {
                                        return 2500;
                                    } else if (squareValue < 50 + 5) {
                                        return 2500;
                                    } else if (squareValue < 60 + 5) {
                                        return 2500;
                                    } else if (squareValue < 70 + 5) {
                                        return 2500;
                                    } else if (squareValue < 80 + 5) {
                                        return 4000;
                                    } else if (squareValue < 90) {
                                        return 6000;
                                    } else {
                                        return 10000;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                id: "support",
                text: "Поддерживающая",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 95;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 95;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 95;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 95;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 95;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 95;
                            } else if (squareValue < 90) {
                                return 90 * 95;
                            } else {
                                return squareValue * 90;
                            }
                        },
                        additional: [
                            {
                                text: "Мытье окон снаружи",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 1200;
                                    } else if (squareValue < 40 + 5) {
                                        return 1400;
                                    } else if (squareValue < 50 + 5) {
                                        return 1500;
                                    } else if (squareValue < 60 + 5) {
                                        return 1700;
                                    } else if (squareValue < 70 + 5) {
                                        return 1900;
                                    } else if (squareValue < 80 + 5) {
                                        return 2500;
                                    } else if (squareValue < 90) {
                                        return 3000;
                                    } else {
                                        return 5000;
                                    }
                                }
                            },
                            {
                                text: "Поддерживающая уборка на балконе",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 1200;
                                    } else if (squareValue < 40 + 5) {
                                        return 1200;
                                    } else if (squareValue < 50 + 5) {
                                        return 1200;
                                    } else if (squareValue < 60 + 5) {
                                        return 1200;
                                    } else if (squareValue < 70 + 5) {
                                        return 2500;
                                    } else if (squareValue < 80 + 5) {
                                        return 4000;
                                    } else if (squareValue < 90) {
                                        return 6000;
                                    } else {
                                        return 10000;
                                    }
                                }
                            },
                            {
                                text: "Погладить белье",
                                fun: (squareValue: number) => {
                                    if (squareValue < 30 + 5) {
                                        return 1300;
                                    } else if (squareValue < 40 + 5) {
                                        return 1400;
                                    } else if (squareValue < 50 + 5) {
                                        return 1500;
                                    } else if (squareValue < 60 + 5) {
                                        return 1600;
                                    } else if (squareValue < 70 + 5) {
                                        return 1700;
                                    } else if (squareValue < 80 + 5) {
                                        return 1800;
                                    } else if (squareValue < 90) {
                                        return 2500;
                                    } else {
                                        return 3000;
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                id: "build",
                text: "Послестроительная",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 400;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 395;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 390;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 385;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 380;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 375;
                            } else if (squareValue < 90) {
                                return 90 * 370;
                            } else {
                                return squareValue * 350;
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        id: "office",
        text: "Офис",
        list: [
            {
                id: "general",
                text: "Генеральная",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 220;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 215;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 215;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 210;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 210;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 205;
                            } else if (squareValue < 90) {
                                return 90 * 205;
                            } else {
                                return squareValue * 200;
                            }
                        }
                    }
                ]
            },
            {
                id: "support",
                text: "Поддерживающая",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 45;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 40;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 40;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 38;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 35;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 35;
                            } else if (squareValue < 90) {
                                return 90 * 35;
                            } else {
                                return squareValue * 32;
                            }
                        }
                    }
                ]
            },
            {
                id: "build",
                text: "Послестроительная",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 250;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 240;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 235;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 235;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 230;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 225;
                            } else if (squareValue < 90) {
                                return 90 * 220;
                            } else {
                                return squareValue * 200;
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        id: "market",
        text: "Торговый центр",
        list: [
            {
                id: "general",
                text: "Генеральная",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 220;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 215;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 215;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 210;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 210;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 205;
                            } else if (squareValue < 90) {
                                return 90 * 205;
                            } else {
                                return squareValue * 200;
                            }
                        }
                    }
                ]
            },
            {
                id: "support",
                text: "Поддерживающая",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 40;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 40;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 40;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 37;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 37;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 35;
                            } else if (squareValue < 90) {
                                return 90 * 35;
                            } else {
                                return squareValue * 32;
                            }
                        }
                    }
                ]
            },
            {
                id: "build",
                text: "Поддерживающая",
                list: [
                    {
                        id: "default",
                        text: "Стандарт",
                        fun: (squareValue: number) => {
                            if (squareValue < 30 + 5) {
                                return 30 * 275;
                            } else if (squareValue < 40 + 5) {
                                return 40 * 270;
                            } else if (squareValue < 50 + 5) {
                                return 50 * 270;
                            } else if (squareValue < 60 + 5) {
                                return 60 * 265;
                            } else if (squareValue < 70 + 5) {
                                return 70 * 260;
                            } else if (squareValue < 80 + 5) {
                                return 80 * 255;
                            } else if (squareValue < 90) {
                                return 90 * 255;
                            } else {
                                return squareValue * 250;
                            }
                        }
                    }
                ]
            }
        ]
    }
]


export function getLocalRoomType(fieldsState: ICalculatorFieldsState) {
    return Logic.find(e => e.id === fieldsState.selectedRoom).text;
}

export function getLocalService(fieldsState: ICalculatorFieldsState) {
    return Logic.find(e => e.id === fieldsState.selectedRoom)
        .list.find(e => e.id === fieldsState.selectedService).text;
}

export function getLocalSubservice(fieldsState: ICalculatorFieldsState) {
    return Logic.find(e => e.id === fieldsState.selectedRoom)
        .list.find(e => e.id === fieldsState.selectedService)
        .list.find(e => e.id === fieldsState.selectedSubservice).text;
}

/**
 * Интерфейс результата калькуляции
 */
export interface ICalculationResult {
    /**
     * Сумма основной части калькулятора
     */
    primarySum: number,
    /**
     * Сумма дополнительных услуг
     */
    additionalsSum: number,
    /**
     * Базовая сумма
     */
    mainSum: number,

    /**
     * Множитель промокода для базовой суммы
     */
    promoQuantifier: number,
    /**
     * Скалькулированная скидка промокода для базовой суммы
     */
    promoDiscountValue: number,

    /**
     * Количество бонусных баллов
     */
    pointsDiscountValue: number,
    /**
     * Максимальное доступное количество бонусных баллов для траты в этом заказе
     */
    maxAvailablePointsValue: number,
    /**
     * Итоговая сумма
     */
    totalSum: number
}

/**
 * Интерфейс состояния полей калькулятора
 */
export interface ICalculatorFieldsState {
    selectedRoom: RoomType,
    selectedSquare: number,
    selectedService: ServiceType,
    selectedSubservice: string,
    selectedDateTimeUTCString: string,
    checkedAdditionals: string[],
    typedPromo: string,
    promoQuantifier: number,
    usedPoints: number
}

function calculate(fieldsState: ICalculatorFieldsState) : ICalculationResult {
    const subserviceSection = Logic.find(e => e.id === fieldsState.selectedRoom)
        .list.find(e => e.id === fieldsState.selectedService)
        .list.find(e => e.id === fieldsState.selectedSubservice);

    const primarySum = subserviceSection.fun(fieldsState.selectedSquare)

    const sectionAdditionals = subserviceSection.additional ?? []

    let additionalSum = 0;
    if (fieldsState.checkedAdditionals.length > 0 && sectionAdditionals && sectionAdditionals.length > 0) {
        fieldsState.checkedAdditionals.forEach(additional => {
            const foundItem = sectionAdditionals.filter(item => additional === item.text).pop()
            if (foundItem) {
                additionalSum += foundItem.fun(fieldsState.selectedSquare)
            }
        })
    }

    const mainSum = primarySum + additionalSum;
    const discountWithUsePoints = Math.floor(Math.min(mainSum * 0.15, fieldsState.usedPoints))

    return {
        primarySum: primarySum,
        additionalsSum: additionalSum,
        mainSum: mainSum,

        promoQuantifier: fieldsState.promoQuantifier,
        promoDiscountValue: mainSum - mainSum * fieldsState.promoQuantifier,

        pointsDiscountValue: discountWithUsePoints,

        maxAvailablePointsValue: Math.floor(mainSum * 0.15),

        totalSum: mainSum * fieldsState.promoQuantifier - discountWithUsePoints
    }
}

export function getAdditional(roomType: RoomType,
                              serviceType: ServiceType,
                              subserviceType: string): IAdditional[] {
    return Logic.find(e => e.id === roomType)
        .list.find(e => e.id === serviceType)
        .list.find(e => e.id === subserviceType)?.additional ?? []
}

export function getSubservicesByService(roomType: RoomType, serviceType: ServiceType) {
    return Logic.find(e => e.id === roomType)
        .list.find(e => e.id === serviceType);
}


/**
 * Если время возможно для бронирования сейчас
 * @param date
 */
export const isTimePreffered = (date: Date) : boolean => {
    const startWorkHour = 8;
    const endWorkHour = 19;

    return preferDate() < date && date.getHours() >= startWorkHour && date.getHours() < endWorkHour;
}

/**
 * Ближайшая возможная дата бронирования времени клинера
 * @param date
 */
export const preferDate = (date: Date = new Date()): Date => {
    const startWorkHour = 8;
    const endWorkHour = 19;
    const hoursOffset = 3;

    const isBeforeNoon = date.getHours() < 12;
    const isBeforeStartWork = date.getHours() < startWorkHour;

    if (isBeforeStartWork) {
        date.setHours(startWorkHour, 0, 0);
    }

    if (isBeforeNoon) {
        date.setHours(date.getHours() + hoursOffset);
    } else {
        date.setDate(date.getDate() + 1);
        date.setHours(startWorkHour + 1, 0, 0);
    }

    return date;
}


export default calculate;