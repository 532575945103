export enum FontSizes {
    /**
     * Незначительный текст
     */
    smaller = '10px',

    /**
     * Неакцентный текст
     */
    small = '12px',

    /**
     * Умеренный текст, описание по обычным текстом
     */
    medium = '14px',

    /**
     * Содержимое инпутов, обычный текст
     */
    normal = '16px',

    /**
     * Акцентные кнопки, заголовки
     */
    large  = '24px',

    /**
     * Громадный акцентный текст
     */
    enormous = '36px'
}

export enum Colors {
    primary = '#1D9B90',
    primaryContainer = '#FFFFFF',
    secondary = '#203556',
    secondaryContainer = '#DBECEC',
    tertiary = '#000000',
    tertiaryVariant = '#B3B3B3',
    tertiaryContainer = '#DBECEC',
    paymentCardBackground = '#FBFBFB',
    surface = '#FFFFFF'
}

export enum Rounded {
    normal = '10px'
}

export const THEME = {
    colors: Colors,
    rounded: Rounded,
    typography: {
        fontSizes: FontSizes
    }
}